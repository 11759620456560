import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

export function buildDatepicker() {
    function transformElement(inputId, labelText) {
        var element = document.getElementById(inputId)?.closest('.col-12');
        if (!element) return;  // Exit if the element is not found
        var inputElement = document.getElementById(inputId);
        if (!inputElement) return;  // Exit if the input element is not found

        var name = inputElement.getAttribute('name');
        var id = inputElement.getAttribute('id');
        var value = inputElement.getAttribute('value');
        var required = inputElement.hasAttribute('required') ? 'required' : '';
        var placeholder = inputElement.getAttribute('placeholder') || 'tt.mm.jjjj';
        var className = inputElement.getAttribute('class') || '';

        var newStructure = `
            <label for="${id}">
                ${labelText}
            </label>
            <div class="inputGroup datePickerGroup">
                <input
                    name="${name}"
                    id="${id}"
                    placeholder="${placeholder}"
                    value="${value}"
                    type="hidden"
                    class="js-datepicker flatpickr-input ${className}"
                    ${required}
                >
                <i class="icon icon-date"></i>
            </div>
        `;

        element.innerHTML = newStructure;
    }

    if (document.getElementById('input_date')) {
        transformElement('input_date', 'Wunschtermin *');
    }

    if (document.getElementById('input_alt_date')) {
        transformElement('input_alt_date', 'Alternativtermin *');
    }

    function initDatepicker(query = document.querySelectorAll('.js-datepicker'), onValueUpdate = function () { }) {
        let locale = false;

        if (GLOBALS) {
            if (GLOBALS.config.languageCode === "de") {
                locale = German;
            }
        }

        // Get the value of the last option in the select element
        const minDaysSelect = document.getElementById('input_min_days');
        const minDays = minDaysSelect ? parseInt(minDaysSelect.options[minDaysSelect.options.length - 1].value, 10) : 0;

        // Calculate the minimum selectable date
        const today = new Date();
        const minSelectableDate = new Date(today.setDate(today.getDate() + minDays));

        for (let picker of query) {
            flatpickr(picker, {
                locale: locale,
                altInput: true,
                altFormat: "d.m.Y",
                minDate: minSelectableDate,
                dateFormat: "d.m.Y",
                onValueUpdate: onValueUpdate,
                disableMobile: true,
                disable: [
                    function (date) {
                        // Disable Sundays (0 is Sunday in JS Date)
                        return date.getDay() === 0 || date.getDay() === 6;
                }
                ]
            });
        }

        let mobileFlatpickr = document.querySelectorAll('.flatpickr-mobile');
        mobileFlatpickr.forEach(function (el) {
            flatpickr(el, {
                altInput: true,
                altFormat: "d.m.Y",
                dateFormat: "d.m.Y",
                onValueUpdate: onValueUpdate,
                disableMobile: true,
                disable: [
                    function (date) {
                        return date.getDay() === 0;
                    }
                ]
            });
        });
    }

    if (document.getElementById('input_date') || document.getElementById('input_alt_date')) {
        initDatepicker(document.querySelectorAll('#input_date, #input_alt_date'));
    }
}
