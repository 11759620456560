let lastScrollTop = 0;
let lastVisibleScrollTop = 0;
const header = document.querySelector('.headerMain');

export function handleHeaderScroll() {
    window.addEventListener('scroll', () => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (scrollTop > lastScrollTop) {
            if (header.classList.contains('show') && scrollTop > lastVisibleScrollTop + 200) {
                header.classList.remove('show');
            }
        } else {
            if (!header.classList.contains('show')) {
                header.classList.add('show');
            }
            lastVisibleScrollTop = scrollTop;
        }

        lastScrollTop = scrollTop;
    });
}


function getFormElements() {
    if (window.innerWidth <= 1540) {
        return {
            formContainer: document.querySelector('.mobileHeaderRight form'),
            searchInput: document.querySelector('.mobileHeaderRight #input_search'),
            submitButton: document.querySelector('.mobileHeaderRight #button')
        };
    } else {
        return {
            formContainer: document.querySelector('.headerTopRight form'),
            searchInput: document.querySelector('.headerTopRight #input_search'),
            submitButton: document.querySelector('.headerTopRight #button')
        };
    }
}

export function addEventListeners() {
    var elements = getFormElements();
    if (!elements.formContainer) return;

    if (elements.searchInput) {
        elements.searchInput.placeholder = "Suchen ...";
    }

    var searchPreviews = document.querySelectorAll('header .searchPreview');
    var iconSearch = document.querySelector('header .searchPreview .icon-search');

    searchPreviews.forEach(function (searchPreview) {
        searchPreview.addEventListener('click', function (event) {
            event.stopPropagation();
            elements.formContainer.classList.toggle('show');
            if (elements.formContainer.classList.contains('show')) {
                focusInput(elements.searchInput);
            }
        });
    });

    iconSearch.addEventListener('click', function (event) {
        event.stopPropagation();
        if (elements.searchInput.value.trim() === "") {
            if (elements.formContainer.classList.contains('show')) {
                elements.formContainer.classList.remove('show');
            } else {
                elements.formContainer.classList.add('show');
                focusInput(elements.searchInput);
            }
        } else {
            if (!elements.formContainer.classList.contains('show')) {
                elements.formContainer.classList.add('show');
                focusInput(elements.searchInput);
            } else {
                elements.submitButton.click();
            }
        }
    });

    document.addEventListener('click', function (event) {
        if (!elements.formContainer.contains(event.target) && !Array.from(searchPreviews).some(sp => sp.contains(event.target))) {
            elements.formContainer.classList.remove('show');
        }
    });

    function focusInput(inputElement) {
        if (inputElement) {
            setTimeout(function() {
                inputElement.focus();
            }, 100);
        }
    }
}



export function handleResize() {
    window.addEventListener('resize', function () {
        var { formContainer, searchInput, submitButton } = getFormElements();
        if (!formContainer) return; // Early return if formContainer is not found

        formContainer.classList.remove('show');
        var cloneFormContainer = formContainer.cloneNode(true);
        formContainer.parentNode.replaceChild(cloneFormContainer, formContainer);

        addEventListeners();
    });
}


export function handleHover() {
    const textcontentElements = document.querySelectorAll('.headerTopRight .textcontent, .mobileHeaderRight .textcontent');
    const locationMenuElements = document.querySelectorAll('.locationMenu');
    let hideTimeout;

    textcontentElements.forEach(item => {
        item.addEventListener('mouseenter', function() {
            clearTimeout(hideTimeout);
            const locationMenu = this.nextElementSibling;
            if (locationMenu && locationMenu.classList.contains('locationMenu')) {
                locationMenu.classList.add('active');
            }
        });

        item.addEventListener('mouseleave', function() {
            const locationMenu = this.nextElementSibling;
            if (locationMenu && locationMenu.classList.contains('locationMenu')) {
                hideTimeout = setTimeout(() => {
                    locationMenu.classList.remove('active');
                }, 300);
            }
        });
    });

    locationMenuElements.forEach(item => {
        item.addEventListener('mouseenter', function() {
            clearTimeout(hideTimeout);
            this.classList.add('active');
        });

        item.addEventListener('mouseleave', function() {
            hideTimeout = setTimeout(() => {
                this.classList.remove('active');
            }, 300);
        });
    });
}


