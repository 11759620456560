import {getOptionsFromJsonString} from "../utilities/options";

export function stickyBox(el, options = {}) {

    let defaultOptions = {
        selectorPinArea: ".js-stickyArea",
        minWidth: GLOBALS.gridBreakpoints.md,
        offsetTop: 0
    }

    let elWidth = 0;

    options = {...defaultOptions, ...options};

    options = getOptionsFromJsonString(el.dataset.options, options);

    let pinArea = el.closest(options.selectorPinArea);

    function resetBox() {
        el.style.position = "";
        el.style.top = "";
        el.style.width = "";
        el.classList.remove('sticky');
    }

    function setBoxSticky() {
        el.style.position = "fixed";
        el.style.top = options.offsetTop + "px";
        el.style.width = elWidth + "px";
        el.classList.add('sticky');
    }

    function checkBox(event) {
        if(event && (event.type === "orientationchange" || event.type === "resize")){
            pinArea.style.minHeight = "";
        }
        let elPosition = el.getBoundingClientRect();
        if (!el.classList.contains('sticky')) {
            elWidth = elPosition.width;
        }
        let pinAreaPosition = pinArea.getBoundingClientRect();
        if (elPosition.height < pinAreaPosition.height) {
            pinArea.style.minHeight = pinAreaPosition.height + "px";
            if ((pinAreaPosition.y - options.offsetTop) <= 0 && window.innerWidth >= options.minWidth) {
                setBoxSticky();
                let topPosition = options.offsetTop;
                let spacerBetweenBoxAndAreaEnd = pinAreaPosition.height + pinAreaPosition.top;
                if ((spacerBetweenBoxAndAreaEnd - options.offsetTop) <= elPosition.height) {
                    topPosition = pinAreaPosition.height + pinAreaPosition.top - elPosition.height;
                }
                el.style.top = topPosition + "px";
            } else {
                resetBox();
            }
        }
    }

    if (pinArea) {
        checkBox();
        window.addEventListener("scroll", checkBox);
        window.addEventListener("resize", checkBox);
        window.addEventListener("orientationchange", checkBox);
    } else {
        console.error("Box Area not found");
    }
}