import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function initializeGSAPAnimations() {
    gsap.registerPlugin(ScrollTrigger);

    const sections = gsap.utils.toArray('.scroll-animation > .gp');
    const totalSections = sections.length;
    const lastSection = sections[sections.length - 1];
    const screenHeight = window.innerHeight;
    const endScrollDistance = screenHeight + screenHeight * 0.5;

    if (document.querySelector(".scroll-animation")) {
        lastSection.classList.add('last');

        gsap.set(sections, {
            opacity: 0.5,
            filter: 'blur(10px)',
        });
        gsap.set(sections[0], {
            opacity: 1,
            filter: 'blur(0)',
        });

        ScrollTrigger.create({
            trigger: '.scroll-animation',
            start: 'top top',
            end: `+=${endScrollDistance}`,
            pin: true,
            scrub: 1,
            anticipatePin: 1,
            onUpdate: self => {
                const progress = self.progress * (totalSections - 1);
                const index = Math.round(progress);

                sections.forEach((section, i) => {
                    if (i === index || (self.progress >= 1 && i === totalSections - 1)) {
                        gsap.to(section, { opacity: 1, filter: 'blur(0)', duration: 0.3, overwrite: 'auto' });
                        section.classList.add('active');
                    } else {
                        gsap.to(section, { opacity: 0.5, filter: 'blur(10px)', duration: 0.3, overwrite: 'auto' });
                        section.classList.remove('active');
                    }
                });
            }
        });
    }
}
