import Swiper from "swiper";
import { Navigation, Thumbs, EffectFade } from 'swiper/modules';

Swiper.use([Navigation, Thumbs, EffectFade]);

export function initializeSwiper() {
    var swiperAktionen = new Swiper(".aktionenPreview", {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".aktionenPreview .swiper-navigation .swiper-button-next",
            prevEl: ".aktionenPreview .swiper-navigation .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            }
        }
    });

    var swiperAnsprechpartner = new Swiper(".ansprechpartnerList .swiper", {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
            nextEl: ".ansprechpartnerList .swiper-navigation .swiper-button-next",
            prevEl: ".ansprechpartnerList .swiper-navigation .swiper-button-prev",
        },

        breakpoints: {
            540: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            960: {
                slidesPerView: 4,
                spaceBetween: 20
            }
        }
    });

    var swiperLeasing = new Swiper(".leasingPreview", {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".leasingPreview .swiper-navigation .swiper-button-next",
            prevEl: ".leasingPreview .swiper-navigation .swiper-button-prev",
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            }
        },
        on: {
            init: function () {
                var swiper = this;
                swiper.slides.forEach(function (slide) {
                    slide.addEventListener('mouseenter', function () {
                        swiper.slides.forEach(function (s) {
                            if (s === slide) {
                                s.classList.add('enlarged');
                            } else {
                                s.classList.add('blurred');
                            }
                        });
                    });
                    slide.addEventListener('mouseleave', function () {
                        swiper.slides.forEach(function (s) {
                            s.classList.remove('enlarged');
                            s.classList.remove('blurred');
                        });
                    });
                });
            },
            resize: function () {
                document.documentElement.style.setProperty('--swiper-slides-per-view', this.params.slidesPerView);
            }
        }
    });

    var swiperSlideshow3 = new Swiper(".slideshow--3", {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: ".slideshow--3 .swiper-button-next",
            prevEl: ".slideshow--3 .swiper-button-prev",
        },
        on: {
            init: function () {
                var swiper = this;
                swiper.slides.forEach(function (slide) {
                    slide.addEventListener('mouseenter', function () {
                        swiper.slides.forEach(function (s) {
                            if (s === slide) {
                                s.classList.add('enlarged');
                            } else {
                                s.classList.add('blurred');
                            }
                        });
                    });
                    slide.addEventListener('mouseleave', function () {
                        swiper.slides.forEach(function (s) {
                            s.classList.remove('enlarged');
                            s.classList.remove('blurred');
                        });
                    });
                });
            },
            resize: function () {
                document.documentElement.style.setProperty('--swiper-slides-per-view', this.params.slidesPerView);
            }
        }
    });


    document.documentElement.style.setProperty('--swiper-slides-per-view', swiperLeasing.params.slidesPerView);
}

export function initializeSlideshow() {
    const slideshows = document.querySelectorAll('.slideshow--2');

    slideshows.forEach(slideshow => {
        const bannerElements = Array.from(slideshow.querySelectorAll('.bannerElement'));

        if (bannerElements.length > 0) {
            const initialWidth = bannerElements[1].offsetWidth;

            bannerElements.forEach(banner => {
                const contentText = banner.querySelector('.contentText');
                contentText.style.width = `${initialWidth}px`;
            });

            bannerElements.forEach(banner => {
                banner.addEventListener('mouseenter', () => {
                    bannerElements.forEach(b => {
                        if (b === banner) {
                            b.style.width = '50%';
                            b.classList.remove('blurred');
                        } else {
                            b.style.width = '25%';
                            b.classList.add('blurred');
                        }
                    });
                });

                banner.addEventListener('mouseleave', () => {
                    bannerElements.forEach(b => {
                        b.style.width = `${initialWidth}px`;
                        b.classList.remove('blurred');
                    });
                });
            });
        }
    });
}
