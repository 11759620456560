import {checkJson} from "./checkJson";

export function getOptionsFromJsonString(jsonString = "", defaultOptions = {}) {
    let options = {};

    if (jsonString !== "") {
        let jsonOption = checkJson(jsonString);
        if (jsonOption !== false) {
            options = jsonOption;
        }
    }

    options = {...defaultOptions, ...options};

    return options;
}