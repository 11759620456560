import {base64DecodeUnicode} from "../utilities/base64";
import {initInputBody} from "./inputfile";
import {initInputMaskDate} from "./inputMaskDate";
import {initPhoneCountryFields} from "./phoneCountryField";

function loadRecaptchaHtml(formWrapper){
    formWrapper.innerHTML = base64DecodeUnicode(formWrapper.dataset.html);
    initInputMaskDate(formWrapper.querySelectorAll('input[type="date"],.js-inputMasketDate'));
    initInputBody(formWrapper.querySelectorAll('.fileBody'));
    initPhoneCountryFields(formWrapper.querySelectorAll("input[type=tel]"));
    formWrapper.querySelector("form").addEventListener("submit", function (e) {
        e.preventDefault();
        grecaptcha.execute();
    });
}

export function initRecaptchaForms(){
    const observer = new MutationObserver(function (mutationList,observer){
        for(let mutation of mutationList){
            let target = mutation.target;
            if(target){
                loadRecaptchaHtml(target);
            }
        }
    });

    for(let form of document.querySelectorAll('.js-RecaptchaForm')){
        observer.observe(form, {
            attributes: true,
            childList: false,
            subtree: false
        });
    }
}