export function initializeLocationMap() {
    const locationMap = document.querySelector('.location-map');
    if (locationMap) {
        const svg = locationMap.querySelector('svg');
        const circles = svg.querySelectorAll('circle');
        const texts = locationMap.querySelectorAll('.textcontent');

        function updateTextPositions() {
            const mapRect = locationMap.getBoundingClientRect();
            circles.forEach((circle, index) => {
                const rect = circle.getBoundingClientRect();

                const circleCenterX = rect.left + rect.width / 2;
                const circleCenterY = rect.top + rect.height / 2;

                const textElement = texts[index + 1];
                textElement.style.left = `${circleCenterX - mapRect.left - 50}px`;
                textElement.style.top = `${circleCenterY - mapRect.top - 75}px`;
            });
        }

        updateTextPositions();

        window.addEventListener('resize', updateTextPositions);

        circles[0].addEventListener('mouseover', function() {
            texts[1].style.opacity = '1';
        });
        circles[0].addEventListener('mouseout', function() {
            texts[1].style.opacity = '0';
        });
        circles[1].addEventListener('click', function() {
            window.location.href = '/de/plauen/';
        });

        circles[1].addEventListener('mouseover', function() {
            texts[2].style.opacity = '1';
        });
        circles[1].addEventListener('mouseout', function() {
            texts[2].style.opacity = '0';
        });
        circles[0].addEventListener('click', function() {
            window.location.href = '/de/schwarzenberg/';
        });
    }
}
